<template>
  <li v-if="is_show" class="nav-item has-sub" :class="{
    'open': isOpen,
    'disabled': item.disabled,
    'sidebar-group-active': isActive,
  }">
    <b-link class="d-flex align-items-center" @click="() => updateGroupOpen(!isOpen)">
      <!-- <div
        :style="`background-image: url(${isActive ? item.icon : item.icon});background-cover: cover;width:24px;height:24px;margin-right:1rem;`" /> -->
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate text-dark text-darken-4 fw-bold-500 menu-title__group">{{ item.title
        }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component :is="resolveNavItemComponent(child)" v-for="child in item.children" :key="child.header || child.title"
        ref="groupChild" :item="child" />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { checkPermission } from '@/auth/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// import { ref } from '@vue/composition-api'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_show: false,
    }
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      checkPermission,
    }
  },
  mounted() {
    this.item.children.forEach(submenu => {
      if (checkPermission(submenu.permission)) {
        this.is_show = true
      }
    })
  },
}
</script>

<style></style>
